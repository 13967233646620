(() => {
  let AIP = {
    data: {},
    state: {
      ready_count: 0,
    },
  };

  let Top = {
    win: {
      width: innerWidth,
      height: innerHeight,
    },
    updateSize() {
      this.win.width = innerWidth;
      this.win.height = innerHeight;
    },
    scrollEffect: {
      instance: undefined,
      init() {
        if ($('.js-scroll').length) {
          this.instance = new SCROLL_EFFECT_MODULE({
            elem: '.js-scroll',
            firstElem: '.js-scroll--first',
            displayRatio: 0.8,
            displayReverse: false, // displayRatioReverse: 1.1,
            displayRatioReverse: 0.8,
            firstDelay: 0,
            firstDelaySteps: 100,
            addClassNameActive: 'is-scroll',
            on: {
              Scroll(top) {
              },
              Change(item, pos) {
              },
              In(item, pos) {
              },
              Out(item, pos) {
              },
            },
          });
        }
      },
    },
    modal: {
      instance: undefined,
      template: '' + '<section class="m-modal" id="{{ id }}" data-modal-type>' + '  <div class="m-modal__bg" data-modal-ui-close></div>' + '  <div class="m-modal__content">' + '          {{ content }}' + '  </div>' + '  <div class="m-modal__close-btn" data-modal-ui-close="" role="button"></div>' + '</section>',
      init() {
        if ($('[data-modal]').length) {
          this.instance = new MODAL_MODULE({
            customModalHtml: this.template,
            defaultModalStyle: true,
            duration: 1000,
            on: {
              init() {
              },
              prepOpen() {
              },
              beforeOpen(data, target, state, elem) {
                if (state.elemOpenType === 'youtube') {
                  if (Movie.slide.instance) {
                    Movie.slide.instance.autoplay.stop();
                  }
                }
              },
              beforeClose() {
              },
              afterClose() {
                if (Movie.slide.instance) {
                  Movie.slide.instance.slideNext();
                  Movie.slide.instance.autoplay.start();
                }
              },
            },
          });
        }
      },
    },
    switchFunc(mql) {
      if (mql.matches) {
        // PC
        if (Bnr.slide.instance) {
          Bnr.slide.destroy();
          if (Bnr.slide.firstLength > 2) {
            Bnr.slide.createSwiper();
          }
        }
      } else {
        // SP
        if (Bnr.slide.instance) {
          Bnr.slide.destroy();
          if (Bnr.slide.firstLength > 1) {
            Bnr.slide.createSwiper();
          }
        }
        if (Bnr.slide.instance == undefined) {
          if (Bnr.slide.firstLength > 1) {
            Bnr.slide.createSwiper();
          }
        }
      }
    },
  };

  mql.addListener(Top.switchFunc);

  let Op = {
    skipFlg: false,
    duration: 1380,
    config: {
      elm: '#js-op',
    },
    hide() {
      $(this.config.elm).hide();
    },
    skip() {
      $body.addClass('is-op-end');
    },
    start() {
      this.setAccessKey();
      setTimeout(() => {
        $body.addClass('is-op-end');
      }, 150);
    },
    setAccessKey() {
      sessionStorage.setItem('op_skip', true);
      // console.log('sessionStorage', sessionStorage.getItem('op_skip'));
    },
    check() {
      if (sessionStorage.getItem('op_skip') != null) {
        this.hide();
        this.skipFlg = true;
      } else {
        this.skipFlg = false;
      }
    },
  };

  let Hero = {
    config: {
      $inner: $('.p-hero'),
      $innerOp: $('.p-op'),
      $scroll: $('.p-hero__scroll'),
    },
    fitWinHeight() {
      this.config.$inner.height(innerHeight);
      this.config.$innerOp.height(innerHeight);
    },
    init() {
      this.config.$inner = $('.p-hero');
      this.config.$innerOp = $('.p-op');
      this.config.$scroll = $('.p-hero__scroll');
      this.fitWinHeight();
      // this.bg.init();
    },
  };

  const kvHeight = () => {
    let h = window.innerHeight;
    let w = window.innerWidth;
    if (w <= 900) {
      $('.p-hero').css('height', h);
    }
  };


  let Bnr = {
    slide: {
      instance: undefined,
      firstLength: null, // loop用に複製される前のlength
      options: {
        pc: {
          speed: 6600,
          loop: true,
          centeredSlides: true,
          loopAdditionalSlides: 4,
          slidesPerView: 3.8,
          spaceBetween: 25,
          freeMode: true,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          slideVisibleClass: 'swiper-slide-visible',
          allowTouchMove: true,
          lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 2,
          },
          autoplay: {
            delay: 0,
            disableOnInteraction: false,
          },
          breakpoints: {
            1000: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 3.1,
            },
          },
        },
        sp: {
          speed: 6000,
          spaceBetween: 15,
          slidesPerView: 1,
          freeMode: true,
          centeredSlides: true,
          loopAdditionalSlides: 2,
          allowTouchMove: true,
          loop: true,
          lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 2,
          },
          autoplay: {
            delay: 0,
            disableOnInteraction: false,
          },
        },
      },
      destroy() {
        this.instance.destroy();
        this.instance = undefined;
        $('.p-hero__bnr-container').removeClass('is-initialized');
      },
      createSwiper() {
        let _options = pcFlg ? this.options.pc : this.options.sp;
        this.instance = new Swiper('.p-hero__bnr-container', _options);

        // 強制敵に画像をロード
        for (let i = 0; i < this.firstLength; i++) {
          this.instance.lazy.loadInSlide(i);
        }

        $('.p-hero__bnr-container').addClass('is-initialized');
      },
      init() {
        let $bnr = $('.p-hero__bnr-slide');
        this.firstLength = $bnr.length;

        if (pcFlg) {
          // PCは3枚以上でスライド起動
          if (this.firstLength < 3) {
            this.options.pc.allowTouchMove = false;
            this.options.pc.autoplay = false;
            this.options.pc.loop = false;
          }
          this.createSwiper();
        }
        if (!pcFlg) {
          if (this.firstLength < 2) {
            this.options.sp.allowTouchMove = false;
            this.options.sp.autoplay = false;
            this.options.sp.loop = false;
          }
          this.createSwiper();
        }
      },
    },
    init() {
      this.slide.init();
    },
  };

  let Info = {
    vue: {
      instance: undefined,
      init() {
        this.instance = new Vue({
          el: '#v-info',
          data: {
            dataLists: AIP.data['info'],
          },
          created() {
          },
          mounted() {
          },
          updated() {
          },
          methods: {},
        });
      },
    },
  };

  let Live = {
    slide: {
      instance: undefined,
      firstLength: null,
      options: {
        speed: 800,
        slidesPerView: 2.8,
        spaceBetween: 40,
        navigation: {
          nextEl: '.p-live__slide-button-next',
          prevEl: '.p-live__slide-button-prev',
        },
        breakpoints: {
          1300: {
            slidesPerView: 2.3,
          },
          1100: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 1,
            spaceBetween: 18,
          },
        },
      },
      createSwiper() {
        this.instance = new Swiper('.p-live__slide-container', this.options);
      },
      init() {
        let $_slide = $('.p-live__slide-slide');
        this.firstLength = $_slide.length;
        if (pcFlg) {
          if (this.firstLength > 2) {
            this.createSwiper();
          } else {
            $('.p-live__slide-nav').hide();
          }
        } else {
          if (this.firstLength > 1) {
            this.createSwiper();
          }
        }
      },
    },
    vue: {
      instance: undefined,
      init() {
        this.instance = new Vue({
          el: '#v-live',
          data: {
            dataLists: AIP.data['live'],
          },
          created() {
          },
          mounted() {
            Live.slide.init();
          },
          updated() {
          },
          methods: {},
        });
      },
    },
  };

  let Movie = {
    slide: {
      instance: undefined,
      firstLength: null,
      options: {
        speed: 900,
        slidesPerView: 1.2,
        loop: true,
        loopAdditionalSlides: 2,
        mousewheel: true,
        preventInteractionOnTransition: true,
        navigation: {
          nextEl: '.p-top_movie_swiper__btn.-prev',
          prevEl: '.p-top_movie_swiper__btn.-next',
        },
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        breakpoints: {
          900: {
            slidesPerView: 1.1,
          },
        },
      },
      createSwiper() {
        let $_container = $('.p-top_movie_swiper__container');
        let $_circle = $('.js-slide-timer');
        let $_pagination_wrap = $('.p-movie__slide-pagination .p-in-wrap');
        let $_pagination_current = $('.p-movie__slide-pagination .p-in-text.-current');
        let $_pagination_prev = $('.p-movie__slide-pagination .p-in-text.-prev');
        let $_pagination_next = $('.p-movie__slide-pagination .p-in-text.-next');

        this.instance = new Swiper('.p-top_movie_swiper__container', this.options);

        $_pagination_prev.text(zeroPadding(this.firstLength));
        $_circle.addClass('is-animate');

        this.instance.on('slideChange', (swiper) => {
          Top.modal.instance.Update();
          // Movie.bg.whenChangeSlide(this.instance.realIndex);
        });
        this.instance.on('beforeTransitionStart', (swiper) => {
          $_circle.removeClass('is-animate');
        });
        this.instance.on('slideChangeTransitionEnd', (swiper) => {
          $_circle.addClass('is-animate');

          // paginationの数字をupdate
          if (this.instance.realIndex === 0) {
            $_pagination_prev.text(zeroPadding(this.firstLength));
          } else {
            $_pagination_prev.text(zeroPadding(this.instance.realIndex));
          }
          if (this.instance.realIndex === this.firstLength - 1) {
            $_pagination_next.text(zeroPadding(1));
          } else {
            $_pagination_next.text(zeroPadding(this.instance.realIndex + 2));
          }
          $_pagination_current.text(zeroPadding(this.instance.realIndex + 1));

          $_pagination_wrap.removeClass('is-next is-prev');
        });
        // スライドが進んだ際
        this.instance.on('slideNextTransitionStart', (swiper) => {
          $_pagination_wrap.removeClass('is-prev').addClass('is-next');
        });
        // スライドが戻った際
        this.instance.on('slidePrevTransitionStart', (swiper) => {
          $_pagination_wrap.removeClass('is-next').addClass('is-prev');
        });
      },
      setTextWidth() {
        $('.p-movie__slide-text .p-in-text p').css('width', `${$('.p-movie__slide-text .p-in-dummy').outerWidth()}px`);
      },
      init() {
        this.setTextWidth();
        if (this.firstLength > 2) {
          this.createSwiper();
        } else {
          $('.p-top_movie_swiper__slide').addClass('swiper-slide-active');
          $('.p-movie__slide-util').hide();
        }

        Top.modal.init();
      },
    },
    bg: {
      player: [],
      movieLength: 0,
      playingIndex: 0,
      config: {
        $bg: $('.p-movie__player-item'),
      },
      state: {
        readyCount: 0,
      },
      readyMovie() {
        this.state.readyCount++;
        if (this.state.readyCount >= this.movieLength) {
        }
      },
      whenChangeSlide(activeIndex) {
        this.config.$bg.removeClass('is-active').eq(activeIndex).addClass('is-active');

        this.config.$bg.removeClass('is-movie-playing');

        this.player[activeIndex].playVideo();
        this.player[this.playingIndex].pauseVideo();

        this.playingIndex = activeIndex;
      },
      init() {
        let _this = this;

        // console.log('hoge');
        this.movieLength = this.config.$bg.length;

        this.config.$bg.eq(0).addClass('is-active');

        // 2. This code loads the IFrame Player API code asynchronously.
        let tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
          this.config.$bg.each((i, val) => {
            let _movieId = $(val).data('videoid');

            _this.player[i] = new YT.Player(`movie_bg_player${i + 1}`, {
              videoId: _movieId,
              playerVars: {
                rel: 0,
                loop: 1,
                playlist: _movieId,
                controls: 0,
                disablekb: 1,
                playsinline: 1,
                start: 8,
                'html5': 1,
              },
            });

            _this.player[i].addEventListener('onReady', () => {
              _this.player[i].mute();
              _this.readyMovie();

              if (i === 0) {
                Bnr.init();
                if (Op.skipFlg) {
                  Op.skip();
                } else {
                  Op.start();
                }
                Top.scrollEffect.init();
                _this.player[i].playVideo();
              }
            });

            _this.player[i].addEventListener('onStateChange', (data) => {
              if (data.data === 1) {
                this.config.$bg.eq(i).addClass('is-movie-playing');
              }
            });
          });
        };
      },
    },
    vue: {
      instance: undefined,
      init() {
        this.instance = new Vue({
          el: '#v-movie',
          data: {
            dataLists: AIP.data['movie'],
          },
          created() {
          },
          mounted() {
            Movie.init();
          },
          updated() {
          },
          methods: {},
        });
      },
    },
    init() {
      let $_slide = $('.p-top_movie_swiper__slide');
      this.slide.firstLength = $_slide.length;

      if (this.slide.firstLength) {
        Movie.slide.init();
        // Movie.bg.init();
      }
    },
  };

  let _topData = sessionStorage.getItem('yutori_top_data');
  _topData = null; // TODO: 削除
  if (_topData) {
    AIP.data = JSON.parse(_topData);
    Info.vue.init();
    Live.vue.init();
    Movie.vue.init();
  } else {
    let MDM = new MULTI_DATA_MODULE({
      data_type: 'jsonp',
      jsonpCallback: 'callback',
      data_list: [{
        // 0 information
        url: `${jsonDirectory}${_artistFolder}/information/start/0/count/4`,
        hierarchy: 'items',
        customFunction(data) {
          // 日付のカスタムデータを作成
          let _newData = data.slice();
          for (let i = 0; i < _newData.length; i++) {
            _newData[i]['date_obj'] = makeDateData(_newData[i].date);
          }
          return _newData;
        },
      }, {
        // 2 live
        url: `${jsonDirectory}${_artistFolder}/live/start/0/count/10`,
        hierarchy: 'items',
        customFunction(data) {
          // ツアーの初日と最終日を取得
          let _newData = data.slice();
          for (let i = 0; i < _newData.length; i++) {
            // idだけのデータを作成
            let _splitURL = _newData[i].link.split('/');
            _newData[i]['id'] = _splitURL[_splitURL.length - 1];

            _newData[i]['firstDate'] = '';
            _newData[i]['lastDate'] = '';

            if (_newData[i].liveItem.length > 1) {
              // 念のため日付順に並び替え
              _newData[i].liveItem.sort((a, b) => {
                if (a.date < b.date) {
                  return -1;
                } else {
                  return 1;
                }
              });
              _newData[i]['firstDate'] = _newData[i].liveItem[0].date;
              _newData[i]['lastDate'] = _newData[i].liveItem[_newData[i].liveItem.length - 1].date;

              // 最終日と初日が同じ日付だった場合、最終日を削除
              if (_newData[i]['lastDate'] === _newData[i]['firstDate']) {
                _newData[i]['lastDate'] = '';
              }
            }
            // liveItemが一個しかなかった場合はfirstDateにのみ値を入れておく
            if (_newData[i].liveItem.length === 1) {
              _newData[i].firstDate = _newData[i].liveItem[0].date;
            }
          }
          return _newData;
        },
      }, {
        // 3 movie
        url: `${jsonDirectory}${_artistFolder}/video/category/ALL/start/0/count/30`,
        hierarchy: 'items',
        customFunction(data) {
          // console.log('data', data);

          let _yt = data.filter((value) => {
            // youtubeのデータだけを抽出
            return value.video_source_type === 'YT';
          });
          return _yt;
        },
        },
        {
          // 3 profile
          url: `${jsonDirectory}${_artistFolder}/profile`,
          hierarchy: 'items',
          customFunction(data) {
            // console.log('data', data);
            return data;
          },
        }],
      on: {
        Update(data, count) {
        },
        Complete(data, list) {
          // info
          AIP.data['info'] = list[0].slice(0, 3);
          Info.vue.init();

          // live
          AIP.data['live'] = list[1].slice(0, 3);
          Live.vue.init();

          // movie
          // 最新5件のみ取得
          if (list[2].length > 0) {
            AIP.data['movie'] = list[2];
          } else {
            AIP.data['movie'] = [{}];
            $('#v-movie').hide();
          }
          $('#v-movie').attr('data-movie-length', AIP.data['movie'].length);
          Movie.vue.init();

          // profile
          AIP.data['prof'] = list[3];
          // console.log(AIP.data['prof']);
          $('.p-biography__text').html(AIP.data['prof'].body_text);

          // console.log(list);
          $body.addClass('is-data-loaded');
          sessionStorage.setItem('yutori_top_data', JSON.stringify(AIP.data));
        },
      },
    });
  }

  const setSlider = () => {
    // swiper

    $('[data-slider-basic]').each(function () {
      let topslide = new Swiper($(this).find('.swiper-container'), {
        slidesPerView: 3,
        autoHeight: true,
        speed: 1500,
        clickable: true,
        loop: true, // loopAdditionalSlides: 1,
        autoplay: {
          delay: 5500,
          disableOnInteraction: false,
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        breakpoints: {
          900: {
            slidesPerView: 2,
            centeredSlides: true,
          },
        },
      });
    });

    $('[data-slider-prof]').each(function () {
      let mvdetailSlide = new Swiper($(this).find('.swiper-container'), {
        slidesPerView: 1,
        autoHeight: true,
        speed: 1000,
        clickable: true,
        normalizeSlideIndex: true,
        loop: true,
        loopAdditionalSlides: 0,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      });
      $('.js-modal-prof').on('click', function () {
        let memberIndex = $(this).index();
        mvdetailSlide.slideTo(memberIndex + 1, 0);
      });
    });
  };

  const modal = () => {
    $('.js-modal-prof').on('click', function () {
      let target = $(this).attr('data-prof-modal');
      if ($('.l-modal.is-modal-open').length) {
        if (!$('.l-modal.is-modal-open').hasClass(target)) {
          $('.l-modal.is-modal-open').removeClass('is-modal-open');
        }
      }
      $(target).addClass('is-modal-open');
      if (!$('body').hasClass('is-modal-open')) {
        $('body').addClass('is-modal-open');
      }
    });
    $('.js-modal__close').on('click', function () {
      $('.l-modal.is-modal-open').removeClass('is-modal-open');
      $('body').removeClass('is-modal-open');
    });
  };

  const youtube = () => {
    let voteFlg;
    let tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    let youtubeId = $('[data-youtube]').attr('data-youtube');
    let startTime = $('[data-start]').attr('data-start');
    let endTime = $('[data-end]').attr('data-end');
    let moviePlayer;
    window.onYouTubeIframeAPIReady = function () {
      console.log(startTime);
      if (!startTime) {
        startTime = 0;
      }

      moviePlayer = new YT.Player('p-top__iframe', {
        videoId: youtubeId,
        playerVars: {
          rel: 0,
          loop: 0, // list: youtubeId,
          modestbranding: true,
          playList: youtubeId,
          controls: 0,
          showinfo: 0,
          disablekb: 1,
          playsinline: 1,
          'html5': 1,
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange,
        },
      });
    };
    let checkCurrentTimeTimer = null;

    function checkCurrentTime() {
      let _time = moviePlayer.getCurrentTime();

      if (endTime < _time) {
        moviePlayer.seekTo(startTime);
      }
    } // プレーヤーの準備ができたとき

    function onPlayerReady(event) {
      moviePlayer.mute();
      moviePlayer.playVideo();
      console.log(event.data);
    }
    function onPlayerStateChange(event) {
      console.log(event.data);
      let status = event.data;
      let names = {
        '-1': '未開始',
        '0': '終了',
        '1': '再生中',
        '2': '停止',
        '3': 'バッファリング中',
        '5': '頭出し済み',
      };
      if (status == 1) {
        $('body').addClass('is-top-movie-active');

        if (!checkCurrentTimeTimer) {
          checkCurrentTimeTimer = setInterval(checkCurrentTime, 1000);
        }
      }

      if (status == 0) {
      }
    }
  };

  const topNavColor = () => {
    let wTop = $(window).scrollTop();
    let heroH = $('.p-hero').innerHeight();
    if (wTop >= heroH) {
      $('body').addClass('is-black');
    } else {
      $('body').removeClass('is-black');
    }
  };

  let picture = document.getElementsByClassName('p-picture')[0];
  let aboutBg = document.getElementsByClassName('p-about')[0];
  let maskBg = document.getElementsByClassName('p-mask')[0];
  let member = document.getElementsByClassName('p-member')[0];

  $(function () {
    Op.check();
    $('.p-op__bg-item').each((i, val) => {
      $(val).addClass(`delay-${getRandomInt(1, 9)}`);
    });
    modal();
    // youtube();
  });

  $(window).on('load', function () {
    setSlider();
    // opControl();
    Top.scrollEffect.init();
  });


  $(window).on('scroll load', function () {
    topNavColor();
    Hero.fitWinHeight();
  });

  $(window).on('resize', function () {
    Hero.init();
  });
})();

$(window).on('load', () => {
  // MovieHero.init();
});


const MovieHero = {
  player: undefined,
  movie: [],
  config: {
    area: '.js-hero-movie',
  },
  state: {
    index: null,
    event: true,
    first: true,
  },
  set(data) {
    let _this = this;
    this.player = new YT.Player('top_iframe', {
      videoId: data.id,
      playerVars: {
        rel: 0,
        loop: 1,
        playlist: data.id,
        controls: 1,
        disablekb: 1,
        playsinline: 1,
        'html5': 1,
      },
    });
    this.player.addEventListener('onReady', () => {
      this.player.mute();
      this.player.playVideo();
      $(this.config.area).addClass('is-mv-load');
    });
    this.player.addEventListener('onStateChange', state => {
      if (state.data === 3) {
        $(this.config.area).addClass('is-mv-loading');
      }
      if (state.data === 1) {
        $(this.config.area).addClass('is-mv-play');
        $(this.config.area).removeClass('is-mv-loading');
        if (this.state.first) {
          this.state.first = false;
          setTimeout(() => {
            this.seek(data);
          }, 500);
        }
      }
    });

  },
  seek(data) {
    clearTimeout(this.seekTimer);
    if (data.end && data.start) {
      this.player.seekTo(data.start, true);
      this.seekTimer = setTimeout(() => {
        this.seek(data);
      }, (data.end - data.start) * 500);
    }
  },
  destroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  },
  init() {
    if (typeof TOP_MOVIE_DATA.id !== 'undefined' && TOP_MOVIE_DATA.id !== '') {
      this.set(TOP_MOVIE_DATA);
    }
  },
};

